/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, Subtitle, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnCover, ColumnWrap, Title, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <Column className="--menu pb--02 pt--02" menu={true} name={"3wo3f7ygfkt"} style={{"backgroundColor":"rgba(22, 25, 37, 1)"}}>
          
          <Menu className="--center --full pb--02 pt--02" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box pr--12" style={{"maxWidth":100}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/4652793655114d1ab705f1babba23c2c.svg"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box mt--02" style={{"maxWidth":331}}>
              
              <Subtitle className="subtitle-box fs--24 w--900 subtitle-box--invert ls--02" content={"<a href=\"/\">M.A.T.</a>"}>
              </Subtitle>

              <Text className="text-box w--300 text-box--invert lh--14 mt--0" content={"<a href=\"/\" style=\"color: rgb(255, 255, 255);\">Okna &amp; dveře</a>"}>
              </Text>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/reference"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Reference</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"http://mat-okna.cz/#homepage-sluzby"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Služby</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/poptavka"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Poptávka</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--300 pl--02 pr--02" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Kontakt</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn1 btn-box--pbtn3 fs--18 w--600 pl--02 pr--02" innerClassName="pb--10 pt--08" target={""} content={"<span style=\"color: rgb(255, 255, 255);\">+420 734148215</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <ColumnCover name={"menu-0"}>
        </ColumnCover>


        <Column className="pb--25 pt--50" name={"1a08x7m91pdh"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box pl--0 pr--0" content={"Reference"}>
              </Title>

              <Text className="text-box mt--10 pl--0" content={"Podívejte se na vybrané reference z naši 18leté praxe."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"1a08x7m91pdh"} style={{"backgroundColor":"rgba(255,255,255,1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--18 w--600 swpf--uppercase lh--12 pl--0 pr--0" content={"Grand Hotel hradec"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mb--0 mt--10" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/06c57cd538f7441ba5dba57f021997a8_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/06c57cd538f7441ba5dba57f021997a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/06c57cd538f7441ba5dba57f021997a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/06c57cd538f7441ba5dba57f021997a8_s=860x_.jpg 860w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/da971a089c7c414183c9d1200cfcaa89_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/da971a089c7c414183c9d1200cfcaa89_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/da971a089c7c414183c9d1200cfcaa89_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/da971a089c7c414183c9d1200cfcaa89_s=860x_.jpg 860w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/20614c2a28cc4af09779dc2ae417bf1c_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/9f0b86c1fe9d42efb1c58cc06c5e75dc_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9f0b86c1fe9d42efb1c58cc06c5e75dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9f0b86c1fe9d42efb1c58cc06c5e75dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9f0b86c1fe9d42efb1c58cc06c5e75dc_s=860x_.jpg 860w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/08595acfe8fa44f1b9bbfc520962e8f7_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/08595acfe8fa44f1b9bbfc520962e8f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/08595acfe8fa44f1b9bbfc520962e8f7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/08595acfe8fa44f1b9bbfc520962e8f7_s=860x_.jpg 860w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/a6838e91fb804643a0819ec10c91ab7d_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/a6838e91fb804643a0819ec10c91ab7d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/a6838e91fb804643a0819ec10c91ab7d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/a6838e91fb804643a0819ec10c91ab7d_s=860x_.jpg 860w"} position={{"x":"0%","y":"-63.768115942028984%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/172f79617298498cab20252fb5458bc2_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/172f79617298498cab20252fb5458bc2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/172f79617298498cab20252fb5458bc2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/172f79617298498cab20252fb5458bc2_s=860x_.jpg 860w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"1a08x7m91pdh"} style={{"backgroundColor":"rgba(255,255,255,1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--18 w--600 swpf--uppercase lh--12 pl--0 pr--0" content={"jaguar"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mb--0 mt--10" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/adccd3cfb747489c97b2dda543692660_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/98faa811d57c45bbad6ac8a61777298c_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/0329360aee3b45df8d7febe9a4f503d0_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-100%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--10" name={"1a08x7m91pdh"} style={{"backgroundColor":"rgba(255,255,255,1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--18 w--600 swpf--uppercase lh--12 pl--0 pr--0" content={"volvo pardubice"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mb--0 mt--10" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/11212/92f84bae63fb4598ade52719e3875f70_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/92f84bae63fb4598ade52719e3875f70_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/92f84bae63fb4598ade52719e3875f70_s=660x_.jpg 660w"} position={{"x":"0%","y":"-84.72222222222214%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-bztsds --parallax pb--60 pt--60" name={"90k7zd4mxkd"} style={{"backgroundColor":"rgba(22,25,37,1)"}} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/11212/8f352f2b8a054914a325520976249deb_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--600 swpf--uppercase lh--12" style={{"color":"var(--color-variable-1)"}} content={"ozvěme se nám"}>
              </Text>

              <Title className="title-box title-box--invert mt--10" content={"Zanechte nám kontakt"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"es5uayoqffa"} style={{"backgroundColor":"rgba(22,25,37,1)"}}>
          
          <ColumnWrap className="column__flex --left el--4" anim={null} animS={null} style={{"maxWidth":""}} columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/4652793655114d1ab705f1babba23c2c.svg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":120}} srcSet={""}>
              </Image>

              <Title className="title-box fs--24 w--900 title-box--invert ls--02 lh--14 mt--10 pl--06" content={"M.A.T."}>
              </Title>

              <Text className="text-box w--300 text-box--invert lh--14 mt--0 pl--08" style={{"maxWidth":336}} content={"Okna &nbsp;a dveře\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">M.A.T. Okna a dveře</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Všestary 173<br>593 12 Hradec Králové&nbsp;<br>IČO 07195702<br>DIČ CZ07195702<br>mat-okna@email.cz &nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">Cenové nabídky</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Tereza Fejtková<br>+420 734 148 215<br>mat-okna@email.cz \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box w--600 swpf--uppercase lh--12" content={"<span style=\"color: rgb(228, 60, 60);\">Technické dotazy</span>"}>
              </Text>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":336}} content={"Technik<br>+420&nbsp;774 774 593<br>mat-okna@email.cz "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}